'use strict'
const axios = require('axios').default;
const getUuid = require('uuid-by-string')

class StyleStore {

    constructor(generateUrl, styleUrl) {
        this.generateUrl = generateUrl;
        this.styleUrl = styleUrl;
    }

    async generateImage(text, image = "") {
        const body = {
            "text": text,
            "image": image
        }

        return await axios.post(this.generateUrl, body)
    }


    async createStyle(text, image) {
        const data=image.split(",");
        const body = {
            "text": text,
            "image": data[1]
        }

        return await axios.post(this.styleUrl, body)
    }


}

export default StyleStore