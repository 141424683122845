import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from "@mui/icons-material/Add";
import { GENERATE_PRINT_URL, IMAGE_URL } from "../../appConstants";
import { useState } from 'react';
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import PrintStore from "./printstore"
import { ImageRepeat } from "./repetable"
import ClipLoader from "react-spinners/ClipLoader";
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const printImgData = new PrintStore(GENERATE_PRINT_URL, IMAGE_URL)


type StyleId = {
  img: string;
};

export const EditPrintDialog: React.FC<StyleId> = ({
  img
}) => {

  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState(img);
  const nameRef = React.useRef(null)
  const getCurrentImgDataFnRef = React.useRef(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setImage(img)
    setOpen(false);
  };

  const handleAdd = () => {
    printImgData.generateImage(
      nameRef.current.value, img
    ).then((response) => {
      setImage(response.data)
    });

  };

  const saveImage = () => {
    console.log("added")
    const {
      imageData: { imageBase64 }
    } = getCurrentImgDataFnRef.current();
    setImage(imageBase64)

    printImgData.createPrint(nameRef.current.value, imageBase64).then(() => {
      window.location.reload();
    })
  };



  return (
    <div>
      <Button
        onClick={handleClickOpen}
        size="small"
        startIcon={<ModeEditIcon />}
        sx={{
          padding: "5px 10px",
        }}
      >
        Edit Print
      </Button>


      {open && (
        <>
          <Dialog open={open} onClose={handleCancel} fullWidth>
            <DialogTitle>New Print</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Text"
                fullWidth
                variant="standard"
                inputRef={nameRef}
              />

              <FilerobotImageEditor
                savingPixelRatio={4}
                previewPixelRatio={1}
                source={image}
                getCurrentImgDataFnRef={getCurrentImgDataFnRef}

                annotationsCommon={{
                  fill: '#ff0000'
                }}
                Text={{ text: 'Edit Image...' }}
                Rotate={{ angle: 90, componentType: 'slider' }}
                tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.FILTERS, TABS.FINETUNE, TABS.RESIZE]} // or {['Adjust', 'Annotate', 'Watermark']}
                defaultTabId={TABS.ANNOTATE} // or 'Annotate'
                defaultToolId={TOOLS.TEXT} // or 'Text'
              />

              <ImageRepeat source={image} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel}>Close</Button>
              <Button onClick={handleAdd}>Generate</Button>
              <Button onClick={saveImage}>Save</Button>
            </DialogActions>
          </Dialog>
        </>
      )

      }


    </div>
  );
}
