'use strict'
const axios = require('axios').default;

import { Component } from "interfaces";

class DesignEdit {
    url : string

    constructor(url: string) {
        this.url = url;
    }

    async getDesign() {
        const response = await axios.get(this.url);
        return response.data;
    }

    async saveDesign() {
        return await axios.put(this.url+"/save")
    }

    async createNewDesign(style_id:string) {

        return await axios.post(this.url+"?style_id="+style_id)
    }

    async applyPrint(print_id:string) {

        const response = await axios.put(this.url+"/print"+"?print_id="+print_id);
        return response.data;
    }

    async activeComponents(components: Component[]) {
        const activeComponents = {
            active_components:components
        }
        const response = await axios.put(this.url+"/activecomponents",activeComponents);
        return response.data;
    }

    async deleteDesign() {
       return await axios.delete(this.url);
    }
}

export default DesignEdit