import { useState } from "react";
import { useTranslate, BaseKey } from "@pankod/refine-core";
import {
    Card,
    CardHeader,
    Box,
    IconButton,
    CardMedia,
    CardContent,
    Typography,
    Tooltip,
    Popover,
    Button,
    Divider,
    TextField,
    Stack

} from "@pankod/refine-mui";

import DeleteIcon from "@mui/icons-material/Delete";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { IDesign } from "interfaces";
import OrderStore from "./orderstore"
import { ORDER_URL ,DESIGN_URL} from "../../appConstants";

import DesignStore from "../../pages/design/designstore"
import { NumberInput } from "../numbers/numberInput";

const orderData = new OrderStore(ORDER_URL)
const designData = new DesignStore(DESIGN_URL)

type DesignItem = {
    design: IDesign;
    // show: (id: BaseKey) => void;
};

export const DesignItem: React.FC<DesignItem> = ({
    design,
    // show,
}) => {
    const t = useTranslate();
    const { id, name, description, svg_image, price } = design;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popoverId = open ? "simple-popover" : undefined;
    const [amount, setAmount] = useState(1);

    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                height: "100%",
            }}
        >
            <CardHeader
                action={
                    <Box component="div">
                        <br />
                        
                        <NumberInput value={amount} setValue={setAmount} />

                        <Button

                            onClick={() => orderData.addDesign(id, amount).then(() => {
                                window.location.reload();
                            })}

                        >
                            Add to Cart
                            <br />
                            <AddShoppingCartIcon />

                        </Button>
                        
                        
                    </Box>
                }
                sx={{ padding: 0 }}
            />

            <CardContent
                sx={{
                    paddingX: "36px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                }}
            >
                <Tooltip title={name}>
                    <Typography
                        sx={{
                            fontWeight: 800,
                            fontSize: "18px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {name}
                    </Typography>
                </Tooltip>
                <Tooltip title={description}>
                    <Typography
                        variant="body2"
                        sx={{
                            mt: 2,
                            overflowWrap: "break-word",
                            color: "text.secondary",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "3",
                            WebkitBoxOrient: "vertical",
                            flex: 1,
                        }}
                    >
                        {description}
                    </Typography>
                </Tooltip>
                <Tooltip title={`${price.toFixed(2)}$`} placement="top">
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "24px",
                            overflowWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                        }}
                    >{`$${price.toFixed(2)}`}</Typography>
                </Tooltip>


                <Divider />
                <Box
                    display="flex"
                    sx={{ m: 10 }}
                    alignItems="center"
                    justifyContent="center"
                >
                    <CardMedia
                        component="iframe"
                        image={svg_image}
                        sx={{
                            width: { xs: 400, sm: 600, lg: 800, xl: 800 },
                            height: { xs: 400, sm: 600, lg: 800, xl: 800 },

                        }}
                        style={{ display: 'flex', justifyContent: 'center' }}
                    />
                </Box>
            </CardContent>
                        <Box>

                        <Button
                            onClick={() => {
                                designData.deleteDesign(
                                    id
                                ).then(() => {
                                    window.location.reload();
                                });

                                setAnchorEl(null);
                            }}
                            size="medium"
                            startIcon={<DeleteIcon />}
                            sx={{
                                padding: "5px 20px",
                            }}
                        >
                            {t("designs.buttons.delete")}
                        </Button>
                        </Box>

        </Card>
    );
};