import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {
    Grid,
    Paper,
    Typography,
    InputBase,
    IconButton,
    Stack,
    Pagination,
    Button
} from "@pankod/refine-mui";
type NumberInputProps = {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;

};


export const NumberInput: React.FC<NumberInputProps> = ({
    value,
    setValue
}) => {
    return (

        <div className="relative flex items-center overflow-hidden">
            
            Quantity
            <Button
            
                className="h-full rounded-tl-md rounded-bl-md border p-2 transition-all hover:bg-gray-50 active:bg-gray-50"
                onClick={() =>
                    setValue((prev) => {
                        if (prev > 0) {
                            return prev - 1;
                        }
                        return 0;
                    })
                }
                size="medium"
            >

                <ArrowDownwardIcon />
            </Button>
            <InputBase
                sx={{ width: 20 }}

                value={value}
                onChange={(event) => {
                    const parseNumber = Number(event.target.value);

                    if (parseNumber >= 0) {
                        setValue(Number(event.target.value));
                    }
                }}
            />
            <Button
                className="h-full rounded-tr-md rounded-br-md border p-2 transition-all hover:bg-gray-50 active:bg-gray-50"
                onClick={() =>
                    setValue((prev) => {
                        if (prev >= 0) {
                            return prev + 1;
                        }
                        return 0;
                    })
                }
                size="medium"
            >
                <ArrowUpwardIcon />
            </Button>


        </div>

    );
};
