import * as React from 'react';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from "@mui/icons-material/Add";
import { GENERATE_STYLE_URL, STYLE_URL } from "../../appConstants";
import { useState } from 'react';
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import StyleStore from "./stylestore"

import CanvasDraw from "react-canvas-draw";
const styleImgData = new StyleStore(GENERATE_STYLE_URL, STYLE_URL)
import UndoIcon from '@mui/icons-material/Undo';
import ClearIcon from '@mui/icons-material/Clear';


export default function DiagramPrint() {
  const [open, setOpen] = React.useState(false);
  const [addSketch, setAddSketch] = React.useState(false);
  const [isImgEditorShown, setIsImgEditorShown] = useState(false);
  const [image, setImage] = React.useState("");
  const nameRef = React.useRef('')

  const getCurrentImgDataFnRef = React.useRef(null);

  const [canvas, setBrush] = useState("#000000");
  const [brush, setThick] = useState(1);

  const [modify, setModify] = useState();
  // const [color, setColor] = useColor("hex", "#121212");
  // const c = "#FCA5A5";
  const style = {
    width: brush + "px",
    background: canvas,
    marginLeft: "50%"
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAddSketch = () => {
    setAddSketch(true);
  };

  const handleCancel = () => {

    setOpen(false);
    setIsImgEditorShown(false);
    setAddSketch(false);
    // setIsRepetableShown(false);
  };

  const handleAdd = () => {
    var image = ""
    if (addSketch) {
      image = modify.getDataURL()
    }

    styleImgData.generateImage(
      nameRef.current.value, image
    ).then((response) => {
      setImage(response.data)
      setIsImgEditorShown(true)
    });

  };

 

  const closeImgEditor = () => {
    setIsImgEditorShown(false);
    setOpen(false)
  };

  const saveImage = () => {
    console.log("added")
    const {
      imageData: { imageBase64 }
    } = getCurrentImgDataFnRef.current();
    setImage(imageBase64)

    styleImgData.createStyle(nameRef.current.value, imageBase64).then(() => {
      window.location.reload();
    })
  };


  const undo = () => {
    modify.undo();
  }

  const clear = () => {
    modify.clear();
  }


  return (
    <div>

      <Button
        onClick={handleClickOpen}
        size="small"
        startIcon={<AddIcon />}
        sx={{
          padding: "5px 10px",
        }}
      >
        New Style
      </Button>


      <Dialog open={open} onClose={handleCancel} fullWidth>
        <DialogTitle>New Style</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Text"
            fullWidth
            variant="standard"
            inputRef={nameRef}
          />


      <Button
        onClick={handleAddSketch}
        size="small"
        startIcon={<AddIcon />}
        sx={{
          padding: "5px 10px",
        }}
      >
        Add Sketch
      </Button>
      {addSketch && (
       <>
          <br />
          <b>Style Sketch</b>
          <br />
          <CanvasDraw
            ref={(canvasDraw) => setModify(canvasDraw)}
            brushColor={canvas}
            brushRadius={brush}
            hideGrid={false}
            canvasWidth={500}
            canvasHeigh={400}
            lazyRadius={0}
            sx={{
              padding: "5px 10px"
            }}
          />


          <Button
            onClick={undo}
            startIcon={<UndoIcon />}
            sx={{
              padding: "5px 10px",
            }}
          >
            Undo
          </Button>


          <Button
            onClick={clear}
            startIcon={<ClearIcon />}
            sx={{
              padding: "5px 10px",
            }}
          >
            Clear
          </Button>
          </>
      )}

          {isImgEditorShown && (
            <>
              <FilerobotImageEditor
                savingPixelRatio={4}
                previewPixelRatio={1}
                source={image}
                getCurrentImgDataFnRef={getCurrentImgDataFnRef}

                annotationsCommon={{
                  fill: '#ff0000'
                }}
                Text={{ text: 'Edit Image...' }}
                Rotate={{ angle: 90, componentType: 'slider' }}
                tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.FILTERS, TABS.FINETUNE, TABS.RESIZE]} // or {['Adjust', 'Annotate', 'Watermark']}
                defaultTabId={TABS.ANNOTATE} // or 'Annotate'
                defaultToolId={TOOLS.TEXT} // or 'Text'
              />

            </>
          )

          }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Close</Button>
          <Button onClick={handleAdd}>Generate</Button>
          {isImgEditorShown && (<Button onClick={saveImage}>Save</Button>)}
        </DialogActions>
      </Dialog>
    </div>
  );
}
