import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from "@mui/icons-material/Add";
import { GENERATE_PRINT_URL, IMAGE_URL } from "../../appConstants";
import { useState } from 'react';
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import PrintStore from "./printstore"
import { ImageRepeat } from "./repetable"
import ClipLoader from "react-spinners/ClipLoader";

const printImgData = new PrintStore(GENERATE_PRINT_URL, IMAGE_URL)

export default function AddPrintDialog() {
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState("");
  const nameRef = React.useRef('')

  const getCurrentImgDataFnRef = React.useRef(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {

    setOpen(false);
    setIsImgEditorShown(false);
    // setIsRepetableShown(false);
  };

  const handleAdd = () => {

    printImgData.generateImage(
      nameRef.current.value, ""
    ).then((response) => {
      setImage(response.data)
      setIsImgEditorShown(true)
    });

  };

  const [isImgEditorShown, setIsImgEditorShown] = useState(false);

  // const [isRepetableShown, setIsRepetableShown] = useState(false);

  const closeImgEditor = () => {

    setIsImgEditorShown(false);
    // setIsRepetableShown(false);
    setOpen(false)

  };

  const saveImage = () => {
    console.log("added")
    const {
      imageData: { imageBase64 }
    } = getCurrentImgDataFnRef.current();
    setImage(imageBase64)

    printImgData.createPrint(nameRef.current.value, imageBase64).then(() => {
      window.location.reload();
      })
    };
  // setIsRepetableShown(true);



return (
  <div>


    <Button
      onClick={handleClickOpen}
      size="small"
      startIcon={<AddIcon />}
      sx={{
        padding: "5px 10px",
      }}
    >
      New Print
    </Button>


    <Dialog open={open} onClose={handleCancel} fullWidth>
      <DialogTitle>New Print</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Text"
          fullWidth
          variant="standard"
          inputRef={nameRef}
        />


        {isImgEditorShown && (
          <>
            <FilerobotImageEditor
              savingPixelRatio={4}
              previewPixelRatio={1}
              source={image}
              getCurrentImgDataFnRef={getCurrentImgDataFnRef}

              annotationsCommon={{
                fill: '#ff0000'
              }}
              Text={{ text: 'Edit Image...' }}
              Rotate={{ angle: 90, componentType: 'slider' }}
              tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.FILTERS, TABS.FINETUNE, TABS.RESIZE]} // or {['Adjust', 'Annotate', 'Watermark']}
              defaultTabId={TABS.ANNOTATE} // or 'Annotate'
              defaultToolId={TOOLS.TEXT} // or 'Text'
            />
            <ImageRepeat source={image} />
          </>
        )

        }

      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Close</Button>
        <Button onClick={handleAdd}>Generate</Button>
        {isImgEditorShown && (<Button onClick={saveImage}>Save</Button>)}
      </DialogActions>
    </Dialog>
  </div>
);
}
