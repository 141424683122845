import { useState } from "react";
import { useTranslate, BaseKey } from "@pankod/refine-core";
import {
    Card,
    CardHeader,
    Box,
    IconButton,
    CardMedia,
    CardContent,
    Typography,
    Tooltip,
    Popover,
    Button,
    Divider,
    TextField
} from "@pankod/refine-mui";
import { Slide } from '@mui/material';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";

import { IProduct } from "interfaces";
import DesignStore from "pages/design/designstore"
import { EDIT_URL } from "../../appConstants";
import { AddStyleDialog } from "./AddStyle"
import DesignEdit from "../../pages/design/designedit"

const designData = new DesignEdit(EDIT_URL)

type PropductItem = {

    product: IProduct;

};

export const ProductItem: React.FC<PropductItem> = ({
    product,

}) => {
    const t = useTranslate();
    const { id, name, description, images, price, svg } = product;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const editDesign = () => {
        designData.createNewDesign(id).then(() => {
            window.location.reload();
        });
    };


    const open = Boolean(anchorEl);
    const popoverId = open ? "simple-popover" : undefined;

    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                height: "100%",
            }}
        >
            <CardHeader
                action={
                    <Box component="div">

                        {/* <AddStyleDialog styleId={id} /> */}

                        <Button
                        onClick={editDesign}
                        size="small"
                        startIcon={<AddIcon />}
                        sx={{
                        padding: "5px 10px",
                        }}
                    >
                        {t("styles.buttons.edit")}
                    </Button>
                    </Box>
                }
                sx={{ padding: 0 }}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >

    {/* <Slide > */}
    <CardMedia
                    component="img"
                    sx={{
                        width: { xs: 60, sm: 84, lg: 108, xl: 144 },
                        height: { xs: 60, sm: 84, lg: 108, xl: 144 },
                        borderRadius: "50%",
                    }}
                    alt={name}
                    image={images[0].url}
                />

<CardMedia
                    component="img"
                    sx={{
                        width: { xs: 60, sm: 84, lg: 108, xl: 144 },
                        height: { xs: 60, sm: 84, lg: 108, xl: 144 },
                        borderRadius: "50%",
                    }}
                    alt={name}
                    image={images[1].url}
                />
    {/* </Slide> */}
                
            </Box>
            <CardContent
                sx={{
                    paddingX: "36px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                }}
            >
                <Divider />
                <Tooltip title={name}>
                    <Typography
                        sx={{
                            fontWeight: 800,
                            fontSize: "18px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {name}
                    </Typography>
                </Tooltip>
                <Tooltip title={description}>
                    <Typography
                        variant="body2"
                        sx={{
                            mt: 2,
                            overflowWrap: "break-word",
                            color: "text.secondary",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "3",
                            WebkitBoxOrient: "vertical",
                            flex: 1,
                        }}
                    >
                        {description}
                    </Typography>

                </Tooltip>

                <Tooltip title={svg.type} placement="top">
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "24px",
                            overflowWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                        }}
                    >{svg.type}</Typography>
                </Tooltip>
                <Tooltip title={`${price.toFixed(2)}$`} placement="top">
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "24px",
                            overflowWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                        }}
                    >{`${price.toFixed(2)}$`}</Typography>
                </Tooltip>

            </CardContent>
        </Card>
    );
};
