'use strict'
const axios = require('axios').default;
const getUuid = require('uuid-by-string')

class PrintStore {

    constructor(generateUrl, imageUrl) {
        this.generateUrl = generateUrl;
        this.imageUrl = imageUrl;
    }

    async generateImage(text, image = "") {
        const body = {
            "text": text,
            "image": image
        }

        return await axios.post(this.generateUrl, body)
    }


    async createPrint(text, image) {
        const data=image.split(",");
        const body = {
            "text": text,
            "image": data[1]
        }

        return await axios.post(this.imageUrl, body)
    }


}

export default PrintStore