'use strict'
const axios = require('axios').default;
const getUuid = require('uuid-by-string')

class DesignStore {

    constructor(url) {
        this.url = url;
    }

    async getDesigns() {
        const response = await axios.get(this.url);
        return response.data;
    }

    async saveDesign(design) {
        design = JSON.parse(design);
        return await axios.put(this.url+"/"+design.id,design)
    }

    async createNewDesign(name,desc) {
        const defaultDesign = {
            "name": name,
            "id": getUuid(name),
            "description": desc,
            "products": [],
            "price": 0.0
        }

        return await axios.post(this.url,defaultDesign)
    }

    // async cloneDesign(id,name,desc) {
    //     const clone_id = (id['id'])
    //     const design = {
    //         "name": name,
    //         "id": getUuid(name),
    //         "description": desc,
    //         "products": [],
    //         "price": 0.0
    //     }

    //     return await axios.post(this.url+"/id/"+clone_id+"/clone",design)
    // }

    async getDesign(id) {
        // set object's Designs to Designs in JSON file
        const response = await axios.get(this.url+"/"+id);
        return response.data;
    }

    async getDesigns() {
        // set object's Designs to Designs in JSON file
        const response = await axios.get(this.url);
        return response.data;
    }

    async addStyle(style_id,design_id) {
        // set object's Designs to Designs in JSON file
        const response = await axios.put(this.url+"/"+design_id+"?style_id="+style_id);
        return response.data;
    }

    async addOrder(id,number) {
        // set object's Designs to Designs in JSON file
        console.log(this.url+"/"+id,id,number)
    }

    async applyPrint(print_id,design_id) {
        // set object's Designs to Designs in JSON file
        const response = await axios.put(this.url+"/"+design_id+"?print_id="+print_id);
        return response.data;
    }

    async addFabric(id) {
        // set object's Designs to Designs in JSON file
        const response = await axios.get(this.url+"/"+id);
        return response.data;
    }

    async deleteDesign(id) {
       return await axios.delete(this.url+"/"+id);
    }
}

export default DesignStore