import * as React from "react";
import { AuthPage as MUIAuthPage, AuthProps } from "@pankod/refine-mui";

import { useRouterContext } from "@pankod/refine-core";

const authWrapperProps = {
    style: {
        background:
            "radial-gradient(50% 50% at 50% 50%,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, 0.5) 100%)",
        backgroundSize: "cover",
    },
};

const renderAuthContent = (content: React.ReactNode) => {
    const { Link } = useRouterContext();

    return (
        <div
            style={{
                margin: "auto",
            }}
        >
            {/* <Link to="/">
                <img
                    
                    src="/images/m2ologo.svg"
                    alt="M2o Editor"
                    style={{ width: "20%", marginBottom: 26 }}
                />
            </Link> */}
            {content}
        </div>
    );
};

export const AuthPage: React.FC<AuthProps> = ({ type, formProps }) => {
    return (
        <MUIAuthPage
            type={type}
            wrapperProps={authWrapperProps}
            renderContent={renderAuthContent}
            formProps={formProps}
        />
    );
};
