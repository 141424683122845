import { useState } from "react";
import { useTranslate, BaseKey } from "@pankod/refine-core";
import {
    Card,
    CardHeader,
    Box,
    IconButton,
    CardMedia,
    CardContent,
    Typography,
    Tooltip,
    Popover,
    Button,
    Divider,
    TextField,
} from "@pankod/refine-mui";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import {AddPrintDialog} from "./AddPrint"
import { Image } from "interfaces";
import { EDIT_URL } from "../../appConstants";
import {EditPrintDialog} from "../../pages/prints/EditPrint"
import DesignEdit from "../../pages/design/designedit"
const designData = new DesignEdit(EDIT_URL)

type ImageItem = {
    // updateStock?: (changedValue: number, clickedProduct: IProduct) => void;
    image: Image;
};

export const ImageItem: React.FC<ImageItem> = ({
    image
}) => {
    const t = useTranslate();
    const { id, name, description, file, price } = image;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const applyPrint = () => {
        designData.applyPrint(id).then(() => {
            window.location.reload();
        });
    };
    const open = Boolean(anchorEl);
    const popoverId = open ? "simple-popover" : undefined;

    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                height: "100%",
            }}
        >
            <CardHeader
                action={
                    <Box component="div">
                        {/* <IconButton
                            aria-describedby={popoverId}
                            onClick={handleClick}
                            sx={{ marginRight: "10px", marginTop: "4px" }}
                            aria-label="settings"
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Popover
                            id={popoverId}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        > */}
                            {/* <Button
                                onClick={() => {
                                    show(id);
                                    setAnchorEl(null);
                                }}
                                size="small"
                                startIcon={<AddIcon />}
                                sx={{
                                    padding: "5px 10px",
                                }}
                            >
                                {t("prints.buttons.add")}
                            </Button> */}

                        <Button
                                onClick={applyPrint}
                                size="small"
                                startIcon={<AddIcon />}
                                sx={{
                                padding: "5px 10px",
                                }}
                            >
                                {t("prints.buttons.add")}
                            </Button>

                            {/* <AddPrintDialog printId={id}/> */}
                            <EditPrintDialog img={file.url} />
                        {/* </Popover> */}
                    </Box>
                }
                sx={{ padding: 0 }}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <CardMedia
                    component="img"
                    sx={{
                        width: "50%",
                        // height: "50%",
                        // borderRadius: "50%",
                    }}
                    alt={name}
                    image={file.url}
                />
            </Box>
            <CardContent
                sx={{
                    paddingX: "36px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                }}
            >
                <Divider />
                <Tooltip title={name}>
                    <Typography
                        sx={{
                            fontWeight: 800,
                            fontSize: "18px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {name}
                    </Typography>
                </Tooltip>
                <Tooltip title={description}>
                    <Typography
                        variant="body2"
                        sx={{
                            mt: 2,
                            overflowWrap: "break-word",
                            color: "text.secondary",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "3",
                            WebkitBoxOrient: "vertical",
                            flex: 1,
                        }}
                    >
                        {description}
                    </Typography>
                </Tooltip>
                {/* <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 700,
                        color: "#999999",
                        my: 1,
                    }}
                >{`#10000${id}`}</Typography> */}
                <Tooltip title={`${price.toFixed(2)}$`} placement="top">
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "24px",
                            overflowWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                        }}
                    >{`${price.toFixed(2)}$`}</Typography>
                </Tooltip>
                {/* {updateStock && (
                    <TextField
                        type="number"
                        margin="dense"
                        size="small"
                        value={product.stock || 0}
                        onChange={(e) => {
                            e.preventDefault();
                            updateStock(parseInt(e.target.value, 10), product);
                        }}
                    />
                )} */}
            </CardContent>
        </Card>
    );
};
