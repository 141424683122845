import React from "react";
import {
    useTranslate,
    IResourceComponentsProps,
    useTable,
    getDefaultFilter,
    HttpError,
} from "@pankod/refine-core";
import { useModalForm } from "@pankod/refine-react-hook-form";
import {
    Grid,
    Paper,
    Typography, 
    InputBase,
    IconButton,
    Stack,
    Pagination,
    CreateButton,
} from "@pankod/refine-mui";
import SearchIcon from "@mui/icons-material/Search";

import {
    ImageFilter,
    ImageItem,
    CreateImage,
    EditImage,
} from "components";
import { Image } from "interfaces";
import AddPrintDialog from "./AddPrint"

export const ImageList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { tableQueryResult, setFilters, setCurrent, filters, pageCount } =
        useTable<Image>({
            resource: "images",
            initialPageSize: 12,
        });

    // const createDrawerFormProps = useModalForm<Image, HttpError, Image>({
    //     refineCoreProps: { action: "create" },
    // });

    // const {
    //     modal: { show: showCreateDrawer },
    // } = createDrawerFormProps;

    // const editDrawerFormProps = useModalForm<Image, HttpError, Image>({
    //     refineCoreProps: { action: "edit" },
    // });

    // const {
    //     modal: { show: showEditDrawer },
    // } = editDrawerFormProps;

    const images: Image[] = tableQueryResult.data?.data || [];

    return (
        <>
            {/* <CreateImage {...createDrawerFormProps} /> */}
            {/* <EditImage {...editDrawerFormProps} /> */}
            <Paper
                sx={{
                    paddingX: { xs: 3, md: 2 },
                    paddingY: { xs: 2, md: 3 },
                    my: 0.5,
                }}
            >
                <Grid container columns={12}>
                    <Grid item xs={12} md={12}>
                        {/* <Stack
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            flexWrap="wrap"
                            padding={1}
                            direction="row"
                            gap={2}
                        > */}
                            {/* <Typography variant="h5">
                                {t("images.images")}
                            </Typography> */}
                            <Paper
                                component="form"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "60%",
                                }}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder={t("images.imageSearch")}
                                    inputProps={{
                                        "aria-label": "image search",
                                    }}
                                    value={getDefaultFilter(
                                        "name",
                                        filters,
                                        "contains",
                                    )}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        setFilters([
                                            {
                                                field: "name",
                                                operator: "contains",
                                                value:
                                                    e.target.value !== ""
                                                        ? e.target.value
                                                        : undefined,
                                            },
                                        ]);
                                    }}
                                />
                                <IconButton
                                    type="submit"
                                    sx={{ p: "10px" }}
                                    aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                                <AddPrintDialog/>
                            </Paper>
                            {/* <CreateButton
                                onClick={() => showEditDrawer()}
                                variant="outlined"
                                sx={{ marginBottom: "5px" }}
                            >
                                {t("stores.buttons.addProduct")}
                            </CreateButton> */}
                        {/* </Stack> */}
                        <Grid container>
                            {images.length > 0 ? (
                                images.map((image: Image) => (
                                    <Grid
                                        item
                                        xs={12}
                                        // md={6}

                                        key={image.id}
                                        sx={{ padding: "8px" }}
                                    >
                                        <ImageItem
                                            image={image}
                                        />
                                    </Grid>
                                ))
                            ) : ( 
                                <Grid
                                    container
                                    justifyContent="center"
                                    padding={3}
                                >
                                    <Typography variant="body2">
                                        {t("images.noImages")}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Pagination
                            count={pageCount}
                            variant="outlined"
                            color="primary"
                            shape="rounded"
                            sx={{
                                display: "flex",
                                justifyContent: "end",
                                paddingY: "20px",
                            }}
                            onChange={(
                                event: React.ChangeEvent<unknown>,
                                page: number,
                            ) => {
                                event.preventDefault();
                                setCurrent(page);
                            }}
                        />
                    </Grid>
                    {/* <Grid
                        item
                        sm={0}
                        md={4}
                        sx={{
                            display: {
                                xs: "none",
                                md: "block",
                            },
                        }}
                    >
                        <Stack padding="8px">
                            <Typography variant="subtitle1">
                                {t("stores.tagFilterDescription")}
                            </Typography>
                            <ImageFilter
                                setFilters={setFilters}
                                filters={filters}
                            />
                        </Stack>
                    </Grid> */}
                </Grid>
            </Paper>
        </>
    );
};
