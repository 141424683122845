import React from "react";
import {
    useTranslate,
    IResourceComponentsProps,
    useTable,
} from "@pankod/refine-core";
import { useModalForm } from "@pankod/refine-react-hook-form";
import {
    Grid,
    Paper,
    Typography,
    InputBase,
    IconButton,
    Stack,
    Pagination,
} from "@pankod/refine-mui";
import SearchIcon from "@mui/icons-material/Search";
import AddDesignDialog from "./CreateDialog"
import {
    DesignItem,
} from "components";
import { IDesign } from "interfaces";


export const DesignList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { tableQueryResult, setFilters, setCurrent, filters, pageCount } =
        useTable<IDesign>({
            resource: "design",
            initialPageSize: 12,
        });

    const products: IDesign[] = tableQueryResult.data?.data || [];
    return (
        <>

                <Grid container columns={16}>
                    <Grid item xs={16} md={16}>
                        <Stack
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            flexWrap="wrap"
                            padding={1}
                            direction="row"
                            gap={2}
                            
                        >
                            <Typography variant="h5">
                                Designs
                            </Typography>
                            <Paper
                                component="form"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: 400,
                                }}
                                
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="design search"
                                    inputProps={{
                                        "aria-label": "design search",
                                    }}

                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        setFilters([
                                            {
                                                field: "name",
                                                operator: "contains",
                                                value:
                                                    e.target.value !== ""
                                                        ? e.target.value
                                                        : undefined,
                                            },
                                        ]);
                                    }}
                                />
                                <IconButton
                                    type="submit"
                                    sx={{ p: "10px" }}
                                    aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                                

                            </Paper>
                            
                        </Stack>
                        
                        <Grid container>
                            {products.length > 0 ? (
                                products.map((design: IDesign) => (
                                    <Grid
                                        item
                                        xs={12}
                                        // md={6}

                                        key={design.id}
                                        sx={{ padding: "8px" }}
                                    >
                                     
                                        <DesignItem
                                            design={design}
                                        />
                                        
                                    </Grid>
                                ))
                            ) : ( 
                                <Grid
                                    container
                                    justifyContent="center"
                                    padding={3}
                                >
                                    <Typography variant="body2">
                                    no designs
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Pagination
                            count={pageCount}
                            variant="outlined"
                            color="primary"
                            shape="rounded"
                            sx={{
                                display: "flex",
                                justifyContent: "end",
                                paddingY: "20px",
                            }}
                            onChange={(
                                event: React.ChangeEvent<unknown>,
                                page: number,
                            ) => {
                                event.preventDefault();
                                setCurrent(page);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={0}
                        md={4}
                        sx={{
                            display: {
                                xs: "none",
                                md: "block",
                            },
                        }}
                    >
                    </Grid>
                </Grid>
{/* 
            </Paper> */}
        </>
    );
};
