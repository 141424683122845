'use strict'
const axios = require('axios').default;
const getUuid = require('uuid-by-string')

class OrderStore {

    constructor(url) {
        this.url = url;
    }

    async getOrders() {
        const response = await axios.get(this.url);
        return response.data;
    }

   
    async getOrder(id) {
        // set object's Orders to Orders in JSON file
        const response = await axios.get(this.url+"/"+id);
        return response.data;
    }

    async addDesign(design_id,number) {
        // set object's Orders to Orders in JSON file
        const response = await axios.post(this.url+"?design_id="+design_id+"&quantity="+number);
        return response.data;
    }
    async deleteOrder(id) {
        return await axios.delete(this.url+"/"+id);
     }
}

export default OrderStore