import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
interface Item {
    source: string
}

export const ImageRepeat: React.FC<Item> = ({ source }) => {

    const itemData = [
        {
            img:  source ,
            title: "1"
        },
        {
            img:  source ,
            title: "2"
        },
        {
            img:  source ,
            title: "3"
        },
        {
            img:  source ,
            title: "4"
        },
        {
            img:  source ,
            title: "5"
        },
        {
            img:  source ,
            title: "6"
        }
    ];

    return (
        <>
            <b>Repetable Images</b>
            <ImageList
                // sx={{ width: 500, height: 450 }}
                cols={3}
                // rowHeight={164}
                gap={0}
            >
                {itemData.map((item) => (
                    <ImageListItem key={item.title}>
                       
                        <img
                            src={item.img}
                            // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </>
    );
}


