import { useState } from "react";
import { useTranslate, BaseKey } from "@pankod/refine-core";
import {
    Card,
    CardHeader,
    Box,
    IconButton,
    CardMedia,
    CardContent,
    Typography,
    Tooltip,
    Popover,
    Button,
    Divider,
    TextField,
    Stack,
    InputLabel,
    Input,
    FormControl

} from "@pankod/refine-mui";

import DeleteIcon from "@mui/icons-material/Delete";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { IDesign, Component } from "interfaces";
import OrderStore from "./orderstore"
import { EDIT_URL } from "../../appConstants";

import DesignEdit from "../../pages/design/designedit"
import { NumberInput } from "../numbers/numberInput";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';

const editDesign = new DesignEdit(EDIT_URL)

function groupBy<T>(arr: T[], fn: (item: T) => any) {
    return arr.reduce<Record<string, T[]>>((prev, curr) => {
        const groupKey = fn(curr);
        const group = prev[groupKey] || [];
        group.push(curr);
        return { ...prev, [groupKey]: group };
    }, {});
}

type EditDesign = {
    design: IDesign;
};

export const EditDesign: React.FC<EditDesign> = ({
    design,
}) => {
    const t = useTranslate();
    const { id, name, description, svg_image, price, print_image, all_components, active_components, base_components, state } = design;

    const [selectedValues, setSelectedValues] = useState({});
    const [selectedComponets, setSelectedComponets] = useState([]);

    const all_components_editable = all_components.filter((e) => {
        if (e.editable) return e
    })

    const component_group = groupBy(all_components_editable, (e) => {
        return e.type
    });

    var active_keys = active_components.reduce(function (map, obj) {
        map[obj.type] = obj.id;
        return map;
    }, {})

    const handleMenuChange = (event: SelectChangeEvent) => {

        const value = event.target.value
        const splitValues = value.split(":")
        selectedValues[splitValues[0]] = splitValues[1]
        selectedComponets.length = 0

        for (let key in selectedValues) {
            const selectedValue = selectedValues[key]

            const groupValues = component_group[key]
            if (selectedValue && groupValues) {
                for (let comp of groupValues) {
                    if (selectedValue == comp.id) {
                        selectedComponets.push(comp)
                    }
                }
            }
        }
        editDesign.activeComponents([...base_components, ...selectedComponets]).then(() => {
            window.location.reload();
        });
    };


    var selectItems = [];
    for (let key in component_group) {

        let values = component_group[key]

        const defaultComponent = active_keys[key]

        const defaultValue = defaultComponent ? defaultComponent : ''
        selectedValues[key] = defaultValue
        const menuitems = values.map((comp: Component) => (
            <MenuItem value={key + ":" + comp.id}>{comp.name}</MenuItem>
        ));

        const sel = <FormControl sx={{ mt: 2, minWidth: 120 }}>
            <InputLabel htmlFor="component">{key.toUpperCase()}</InputLabel>
            <br />
            <br />
            <Select
                autoFocus
                defaultValue={key + ":" + defaultValue}
                onChange={handleMenuChange}
                label={key}
                inputProps={{
                    name: 'comp-name',
                    id: 'comp-id',
                }}
            >
                <MenuItem value={key + ":"}>
                    <em>select value</em>
                </MenuItem>
                {menuitems}
            </Select>
            <br />
        </FormControl>

        selectItems.push(sel)
    }


    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                height: "100%",
            }}
        >
            <CardContent
                sx={{
                    paddingX: "36px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                }}
            >
                <Tooltip title={name}>
                    <Typography
                        sx={{
                            fontWeight: 800,
                            fontSize: "18px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {name}
                    </Typography>
                </Tooltip>
                <Tooltip title={description}>
                    <Typography
                        variant="body2"
                        sx={{
                            mt: 2,
                            overflowWrap: "break-word",
                            color: "text.secondary",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "3",
                            WebkitBoxOrient: "vertical",
                            flex: 1,
                        }}
                    >
                        {description}
                    </Typography>
                </Tooltip>
                <Tooltip title={`${price.toFixed(2)}$`} placement="top">
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "24px",
                            overflowWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                        }}
                    >{`$${price.toFixed(2)}`}</Typography>
                </Tooltip>


                <Divider />
                <Box
                    display="flex"
                    sx={{ m: 10 }}
                    alignItems="center"
                    justifyContent="center"
                >

                    {/* <img src={svg_image} /> */}
                    <CardMedia
                        component="iframe"
                        image={svg_image}
                        sx={{
                            width: { xs: 400, sm: 600, lg: 800, xl: 800 },
                            height: { xs: 400, sm: 600, lg: 800, xl: 800 },

                        }}
                        style={{ display: 'flex', justifyContent: 'center' }}
                    />
                </Box>
                <InputLabel>{'Configurator Options'}</InputLabel>
                <Divider />

                {selectItems}

            </CardContent>
            <Box>

                <Button
                    onClick={() => {
                        editDesign.deleteDesign(

                        ).then(() => {
                            window.location.reload();
                        });


                    }}
                    size="large"
                    startIcon={<DeleteIcon />}
                    sx={{
                        padding: "5px 20px",
                    }}
                >
                    {'Delete Design'}
                </Button>

                <Button
                    onClick={() => {
                        editDesign.saveDesign(

                        ).then(() => {
                            window.location.reload();
                        });


                    }}
                    size="large"
                    startIcon={<SaveIcon />}
                    sx={{
                        padding: "5px 20px",
                    }}
                >
                    {'Save Design'}
                </Button>
            </Box>

        </Card>
    );
};
